<template>
    <div class="height1">
        <!-- <bread></bread> -->
        <div class="iq-top-navbar">
            <el-page-header @back="goBack" content="账单明细">
            </el-page-header>
        </div>
        <!--    内容    -->
        <div class="center-content">
            <div class="iq-card-body">
                <el-table :data="tableData" stripe height="calc(100% - 90px)" style="width: 100%" :header-cell-style="{background:'#F5F7FC'}">
                    <el-table-column prop="userName" label="用户">
                        <template slot-scope="scope">
                            <p>{{ scope.row.userName }}</p>
                            <p>{{ scope.row.userPhone }}</p>
                        </template>
                    </el-table-column>
                    <el-table-column prop="money" label="金额（元）" align="center">
                    </el-table-column>
                    <el-table-column prop="verstionName" label="版本" align="center">
                    </el-table-column>
                    <el-table-column label="开通时长" align="center">
                        <template slot-scope="scope">
                            <p>{{ scope.row.payLength + '天' }}</p>
                        </template>
                    </el-table-column>
                    <el-table-column prop="payWay" label="支付方式" align="center">
                        <template slot-scope="scope">
                            <span v-if="scope.row.payWay == '1'">微信</span>
                            <span v-if="scope.row.payWay == '2'">支付宝</span>
                            <span v-if="scope.row.payWay == '3'">银行卡</span>
                            <span v-if="scope.row.payWay == '0'">余额</span>
                        </template>
                    </el-table-column>
                    <!-- <el-table-column prop="successTime" label="支付时间" align="center">
                        <template slot-scope="scope">{{ scope.row.successTime | timefilters }}</template>
                    </el-table-column> -->
                </el-table>
            </div>
        </div>
    </div>
</template>

<script>
import bread from "../../../components/common/breadcrumb"
import { RenewalUpgrade } from "../../../components/HospitalDomain/RenewalUpgrade";
export default {
    name: "Order",
    components: {
        bread
    },
    data() {
        var renewalUpgrade = new RenewalUpgrade(this.TokenClient, this.Services.Authorization);
        return {
            RenewalDomain: renewalUpgrade,
            tableData: [],
            orderId: this.$route.query.orderId
        }
    },
    mounted() {
        this.GetList();
    },
    methods: {
        goBack() {
            this.$router.go(-1);
        },
        GetList() {
            var _this = this;
            _this.RenewalDomain.myOrderDetail(_this.orderId,
                function (data) {
                    _this.tableData = data.data;
                },
                function (error) {
                    console.log(error);
                }
            );
        }
    }
}
</script>

<style scoped>
.el-page-header {
    line-height: 73px;
    padding-left: 20px;
}
.pay-top {
    position: relative;
}

.payItem {
    line-height: 2;
}

.payItem p {
    float: left;
}

.payItem span {
    float: right;
}

.payNum {
    color: red;
    font-size: 24px;
}

.pay-bg {
    position: relative;
    margin: 20px auto;
    width: 350px;
    height: 436px;
    background: url("../../../assets/img/wx_pay.jpg")top center no-repeat;
    background-size: cover;
}

.pay-bg img {
    position: absolute;
    width: 160px;
    top: 140px;
    left: 100px;
}
</style>
